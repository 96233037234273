import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { ContactHeader, BannerDefault, Title, Section } from "../utils/";
import contactImg from "../utils/img/bcg/contactBcg.jpg";
import ContactForm from "../components/06-contact/ContactForm";
import { graphql, StaticQuery } from "gatsby";

const ContactPage = ({
  dtitle,
  dsubtitle,
  dsectionTitle1,
  dsectionDescription1
}) => (
  <Layout>
    <SEO
      title="XBound"
      keywords={[
        `smarta kontrakt sverige svenskt företag`,
        ` svenskt företag blockchain utveckling sverige`,
        `Blockchain development smarta kontrakt och blockchain utveckling`,
        `Smart Contract development `,
        `Ethereum Development sverige eterumutveckling `,
        `Solidity Development svenskt företag `,
        `Hyperledger Development Sweden`,
        `ICO STO Development Sweden Stockholm Malmo`,
        `Smart contract Audit Sweden smart kontraktskontroll`,
        `Blockchain Consulting and Development Sweden Stockholm Malmo`,
        `Full-Stack Development Sweden Stockholm Malmo`,
        `svensk blockchain utveckling sverige`,
        `svensk blockchain smart kontraktsutveckling`,
        `Full-Stack Development services sverige`,
        `blockchain company Sweden`,
        `ERC20 Ethereum Token Development Sweden`,
        `Swedish Ethereum Development ICO`,
        `gatsby smarta kontrakt och blockchain utveckling sverige`,
        `react sverige svenskt företag `,
        `Nodejs sverige svenskt företag `,
        `JAMStack Swedish Sweden Stockholm Malmo`,
        `Gatsby Website development Sweden`,
        `React Redux Graphql Front developer svenskt företag `,
        `React Redux Graphql Backend development Sweden Stockholm Malmo`,
        `Cryptocurency development Sweden`,
        `Wallet development Cryptocurency Smart Contract`,
        `Website development Sweden`,
        `Website blog hemsida utveckling Sweden Stockholm Malmo`,
        `Website blog hemsida utveckling svenskt företag`
      ]}
    />
    <StaticQuery
      query={CONTACTPAGEQUERY}
      render={data => {
        const contactData = data.contactUsPage.edges[0];
        const {
          title,
          subtitle,
          sectionTitle1,
          sectionDescription1
        } = contactData.node;
        return (
          <div>
            <ContactHeader img={contactImg}>
              {/* <ContactHeader> */}
              <BannerDefault
                title={title || dtitle}
                subtitle={subtitle.subtitle || dsubtitle}
              />
            </ContactHeader>
            <Section>
              <Title
                message={
                  sectionDescription1.sectionDescription1 ||
                  dsectionDescription1
                }
                title={sectionTitle1 || dsectionTitle1}
              />
            </Section>
            <ContactForm />
          </div>
        );
      }}
    />
  </Layout>
);
const CONTACTPAGEQUERY = graphql`
  {
    contactUsPage: allContentfulContactusPage {
      edges {
        node {
          title
          subtitle {
            subtitle
          }
          sectionTitle1
          sectionDescription1 {
            sectionDescription1
          }
        }
      }
    }
  }
`;

ContactPage.defaultProps = {
  dtitle: "Contact us_",
  dsubtitle:
    "XBound is a blockchain consultancy and development company based in Sweden. We are a small team of multicultural and diverse individuals with an entrepreneurial spirit. let us know how we can help_",
  dsectionTitle1: "Get in touch_",
  dsectionDescription1:
    "Thank you for reaching out to us. If you need additional information or have general queries including partnership opportunities, Please feel free to leave us a message or breif about your project. Our beloved team is always open to discuse your vision and thoughts. Please fill up the Form, and we'll do our best to get back to you within one business day_"
};
export default ContactPage;
