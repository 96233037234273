import React from "react";
import styled from "styled-components";
import { styles, BannerButton } from "../../utils";
// import { navigateTo } from "gatsby-link";

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

class ContactForm extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
      this
    );
  }

  handleChange(e) {
    let fields = this.state.fields;
    let name = e.target.name;
    let value = e.target.value;
    fields[name] = value;
    this.setState(
      {
        fields
      },
      () => {
        this.validateField(name, value);
      }
    );
  }
  handleAttachment = e => {
    let fields = this.state.fields;
    let name = e.target.name;
    let value = e.target.files[0];
    fields[name] = value;

    this.setState({ fields });
  };

  handleSubmit = form => {
    // e.preventDefault();
    // const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state.fields
      })
    })
      // .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  submituserRegistrationForm(e) {
    e.preventDefault();
    const form = e.target;
    if (this.validateForm()) {
      let fields = {};
      fields["username"] = "";
      fields["emailid"] = "";
      fields["mobileno"] = "";
      fields["message"] = "";
      fields["myfile"] = "";
      // fields["password"] = "";
      this.setState({ fields: fields });

      // alert("Form submitted");
      this.handleSubmit(form);
    }
  }

  validateField(fieldName, value) {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = this.state.formIsValid;

    switch (fieldName) {
      case "username":
        if (!value) {
          formIsValid = false;
          errors["username"] = "*Please enter your username.";
        }

        if (typeof value !== "undefined") {
          if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
            formIsValid = false;
            errors["username"] = "*Please enter alphabet characters only.";
          }
        }
        break;
      case "emailid":
        if (!value) {
          formIsValid = false;
          errors["emailid"] = "*Please enter your email-ID.";
        } else if (typeof value !== "undefined") {
          //regular expression for email validation
          var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
          );
          if (!pattern.test(value)) {
            formIsValid = false;
            errors["emailid"] = "*Please enter valid email-ID.";
          }
        }
        // else formIsValid = true;
        break;
      case "mobileno":
        if (!value) {
          formIsValid = false;
          errors["mobileno"] = "*Please enter your mobile no.";
        }

        if (typeof value !== "undefined") {
          // if (!value.match(/^[0-9]{10}$/)) { to enforce only 10 numbers
          // if (!value.match(/^[0-9]/)) {
          //   formIsValid = false;
          //   errors["mobileno"] = "*Please enter valid mobile no.";
          // }
        }
        break;
      case "password":
        if (!value) {
          formIsValid = false;
          errors["password"] = "*Please enter your password.";
        }
        if (typeof value !== "undefined") {
          if (
            !value.match(
              /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
            )
          ) {
            formIsValid = false;
            errors["password"] = "*Please enter secure and strong password.";
          }
        }
        break;
      default:
        break;
    }
    this.setState({
      errors: errors,
      isFormValid: formIsValid
    });
    return formIsValid;
  }
  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "*Please enter your name.";
    }

    if (typeof fields["username"] !== "undefined") {
      if (!fields["username"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["username"] = "*Please enter alphabet characters only.";
      }
    }

    if (!fields["emailid"]) {
      formIsValid = false;
      errors["emailid"] = "*Please enter your email.";
    }

    if (typeof fields["emailid"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["emailid"])) {
        formIsValid = false;
        errors["emailid"] = "*Please enter a valid email address.";
      }
    }

    if (!fields["mobileno"]) {
      formIsValid = false;
      errors["mobileno"] = "*Please enter your mobile number.";
    }

    if (typeof fields["mobileno"] !== "undefined") {
      // if (!fields["mobileno"].match(/^[0-9]{10}$/)) {
      //   formIsValid = false;
      //   errors["mobileno"] = "*Please enter a valid mobile number.";
      // }
    }
    if (formIsValid) {
      errors["message"] =
        "Thanks for contacting us, your message has been sent.";
    }

    // if (!fields["password"]) {
    //   formIsValid = false;
    //   errors["password"] = "*Please enter your password.";
    // }

    // if (typeof fields["password"] !== "undefined") {
    //   if (
    //     !fields["password"].match(
    //       /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/
    //     )
    //   ) {
    //     formIsValid = false;
    //     errors["password"] = "*Please enter secure and strong password.";
    //   }
    // }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  render() {
    return (
      <RegisterWrapper>
        <div id="register" className="container">
          <h3>How can we help?</h3>
          <form
            name="contactUsForm"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.submituserRegistrationForm}
          >
            {/* You still need to add the hidden input with the form name to your JSX form */}
            <input
              type="hidden"
              name="form-name"
              value="userRegistrationForm"
            />
            <p hidden>
              <label>
                Don’t fill this out:{" "}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </p>

            {/* User Name */}
            <label>Name</label>
            <input
              type="text"
              name="username"
              aria-label="username"
              value={this.state.fields.username}
              onChange={this.handleChange}
              placeholder="Your Name"
            />
            <div className="errorMsg">{this.state.errors.username}</div>
            {/* Email Address */}
            <label>Email</label>
            <input
              type="text"
              name="emailid"
              aria-label="emailid"
              value={this.state.fields.emailid}
              onChange={this.handleChange}
              placeholder="Your Email ID"
            />
            <div className="errorMsg">{this.state.errors.emailid}</div>
            {/* Mobile Number */}
            <label>Mobile</label>
            <input
              type="text"
              name="mobileno"
              aria-label="mobileno"
              value={this.state.fields.mobileno}
              onChange={this.handleChange}
              placeholder="e.g +46111111111"
            />
            <div className="errorMsg">{this.state.errors.mobileno}</div>
            {/* Message  */}
            <label>Message</label>
            <textarea
              type="text"
              name="message"
              aria-label="message"
              rows={10}
              value={this.state.fields.message}
              onChange={this.handleChange}
              placeholder="Your Message"
            />

            {/* <label>Password</label>
              <input
                type="password"
                name="password"
                value={this.state.fields.password}
                onChange={this.handleChange}
              /> 
            <div className="errorMsg">{this.state.errors.password}</div>*/}
            {/* File  */}
            <input
              type="file"
              name="myfile"
              placeholder="Upload file"
              onChange={this.handleAttachment}
            />
            {/* <div data-netlify-recaptcha="true"> </div> */}
            <BannerButton
              type="submit"
              style={{
                margin: "1.5rem auto",
                fontSize: "0.8rem"
              }}
              className="submitbtn"
            >
              send
            </BannerButton>
            <div className="msgSent">{this.state.errors.message}</div>
          </form>
        </div>
      </RegisterWrapper>
    );
  }
}

/************************************/
/** RegisterWrapper Styled Component */
/************************************/
const RegisterWrapper = styled.section`
  margin: 1rem 0rem 3rem;
  padding:2rem;
  font-family: Arial, Tahoma, sans-serif;
  #register {
    /* width: 300px; */  
    background: ${styles.colors.mainWhite};
    box-shadow: 3px 4px 40px -9px rgba(0, 0, 0, 0.7);
    /* border: 1px solid #d6d7da; */
    padding: 1rem 1rem 2rem 1rem;
    border-radius: 5px;
    /* border-top-right-radius: 10px; */
    line-height: 10px;
    /* color: #333333; */
    font-size: 12px;
  }


  button.submitbtn {
    background-image: linear-gradient(#1476f2, #147fff);
    border-bottom: 1px solid #648c3a;
    cursor: pointer;
    color: #fff;    
  }
  button.submitbtn:hover {
    background-image: linear-gradient(#147fff, #1476f2);
  }
  button.submitbtn:active {
    height: 34px;
    border-bottom: 0;
    margin: 1px 0 0 0;
    background-image: linear-gradient(#147fff, #1476f2);
    box-shadow: inset 0 1px 3px 1px rgba(0, 0, 0, 0.3);
  }
  label,
  input,
  textarea {
    display: block;
    width: 95% !important;
    margin: 0rem auto !important;
  }
   .errorMsg {
    margin-left: 2rem;
    color: #cc0000;
    margin-bottom: 12px;
    margin-top:0.5rem; 
    /* margin-left: 2rem; */
    /* margin-bottom: 12px; */
  }
  
  .msgSent{   
    margin-top:1rem; 
    font-size: 0.9rem;
    /* color: #6b8e23; */
    color: green;
    text-align: center;
  }

  
  /* .sucessMsg {
    color: #6b8e23;
    margin-bottom: 10px;
  } */

  h3 {
    font-size: 1.3rem;
    text-align: center;
    letter-spacing:0.2rem;
    text-transform:capitalize;
    /* color: #555; */
    text-shadow: 1px 1px 0 #eee;
    color:${styles.colors.mainBlueColor};
    margin-bottom:1.5rem;
  }
  label {
    margin-top: 1.4rem !important;
    font-size: 0.85rem;
  }

  .submit-btn:hover {
    /* background: ${styles.colors.mainBlueColor};
    color: ${styles.colors.mainWhite}; */
    /* font-size: 0.76rem; */
  }
  input {
    /* border-bottom: 1px solid #ccc !important; */
    /* height: 3rem !important; */
    /* font-size: 1.1rem !important;
    color:${styles.colors.mainTextClr} !important; */
  }
  textarea {
    height: 6rem;
    margin-top: 1.1rem !important;
    padding: 10px;
    border: 1px solid #ccc;
    border-top-right-radius: 6px;
    /* border-radius: 4px; */
    box-sizing: border-box;
    resize: vertical;
  }
  

  textarea {
    font-size: 1rem;
    color:${styles.colors.mainTextClr};
  }
  textarea::placeholder,
  input::placeholder {
    font-size: 0.7em;
    font-style: italic;
  }

  /* Responsive design */
  @media (max-width: 880px) {    
    margin: 1rem 0rem 4rem;
    padding:1rem;
    h3{
      font-size:1.2rem;
    }
  }
`;

export default ContactForm;
